import GlobalLayout from "components/page/GlobalLayout";
import TeamsPageTemplate from "components/teams/TeamsPageTemplate";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

export default function CSuiteTeamsPage() {
  const renderContent = data => {
    const pageData = {
      headerSection: {
        preTitle: "c-suite",
        title: "Unify and Future-Proof Your Security at Scale ",
        description:
          "Modernize your security cameras, access control, alarms, and sensors to improve visibility, safety, and efficiency at scale.",
        image: data.headerImage,
        button1: { text: "Request Demo", path: null },
      },
      logos: [
        {
          img: data.logo1,
          width: "130px",
          alt: "Hurley",
        },
        {
          img: data.logo2,
          width: "177px",
          alt: "Aramark",
        },
        {
          img: data.logo6,
          width: "137px",
          alt: "Clark Construction",
        },
        {
          img: data.logo4,
          width: "186px ",
          alt: "Los Angeles Film School",
        },
        {
          img: data.logo5,
          width: "149px",
          alt: "Goodwill",
        },
        {
          img: data.logo3,
          width: "115px",
          alt: "Stryker",
        },
      ],
      benefitRows: [
        {
          preTitle: "TECHNOLOGY ROI",
          title: "Deploy Cost-Effective Security",
          description:
            "Enjoy modern enterprise security that reduces your upfront and recurring costs. Eliminate excess hardware, cut back on in-house maintenance, and avoid the expense of false alarms. With a 10-year warranty and unlimited support, your system is secure.",
          specs: [
            {
              title: "40%",
              description: "lower upfront costs vs. legacy systems*",
            },
            {
              title: "10-year",
              description: "industry-leading warranty",
            },
          ],
          note: "*Rhombus case studies and customer surveys",
          image: data.benefitImage1,
        },
        {
          preTitle: "EMPOWER YOUR TEAMS",
          title: "Boost Efficiency & ROI for Security Operations",
          description:
            "Give your teams the tools they need to save time, labor, and resources on physical security. With user-friendly design, native remote access, and automatic updates, security management has never been more efficient.",
          specs: [
            { title: "150%", description: "increase in productivity*" },
            {
              title: "6x",
              description: "lower recurring costs vs. legacy systems*",
            },
          ],
          note: "*Rhombus case studies and customer surveys",
          image: data.benefitImage2,
        },
        {
          preTitle: "MODERNIZE & CENTRALIZE",
          title: "Unify Your Security on a Scalable Platform",
          description:
            "Meet your central nervous system for physical security management. With an all-in-one cloud platform that grows with your needs, it’s easy to keep pace with evolving security standards and set your company up for future safety, efficiency, and success.",
          specs: [
            { title: "#1", description: "in physical security on G2" },
            {
              title: "99.9%",
              description: "uptime for unlimited devices and locations",
            },
          ],
          image: data.benefitImage3,
        },
        {
          preTitle: "LONG-TERM PARTNERSHIP",
          title: "Partner With a Team That Cares",
          description:
            "Experience best-in-class partnership and support throughout your entire lifetime with Rhombus. Work with a dedicated point of contact and in-house team that provides hands-on support, from end-user training to building custom solutions from scratch.",
          specs: [
            { title: "99%", description: "renewal rate, industry record" },
            {
              title: "24/7",
              description: "in-house support with automatic ticket creation",
            },
          ],
          image: data.benefitImage4,
        },
      ],
      infoSlider: {
        title: "Feature-Rich Platform to Empower Your Teams ",
        slides: [
          {
            tabTitle: "Operational Insights",
            preTitle: "Operational Insights ",
            title: "Reporting & Analytics",
            description:
              "Gain insight into occupancy trends to inform data-driven business decisions. Track how people flow through your organization with heat maps, foot traffic, unique people counts, vehicle counts, and third-party software. Layer data on camera feeds for operational context on a single pane of glass.",
            image: data.infoSlide1,
          },
          {
            tabTitle: "Trust & Compliance",
            preTitle: "Trust & Compliance",
            title: "Secure-by-Default",
            description:
              "Rhombus was founded by cybersecurity veterans with decades of experience in data privacy, cloud systems, and modern security architecture. Enjoy effortless cybersecurity on a platform built to adhere to global, regional, and industry regulations—including NDAA and TAA compliance.",
            image: data.infoSlide3,
          },
          {
            tabTitle: "Unlimited Scalability",
            preTitle: "GROW FREELY",
            title: "Unlimited Scalability",
            description:
              "Seamlessly add unlimited locations, users, and devices with no tiers or seat limits. Gain granular control over user permissions with custom roles and access types that make it easy to scale geographically and vertically within your organization.  ",
            image: data.infoSlide2,
          },

          {
            tabTitle: "Automatic Updates",
            preTitle: "HANDS-OFF MANAGEMENT ",
            title: "Automatic Security Updates ",
            description:
              "Firmware updates happen automatically, ensuring that you’re always 100% current with the latest platform features and cybersecurity protocols without having to lift a finger. Save significant time, cost, and labor by eliminating the need to perform manual updates.  ",
            image: data.infoSlide4,
          },
          {
            tabTitle: "Open and Integrated",
            preTitle: "YOUR SECURITY, YOUR WAY ",
            title: "Open & Integrated ",
            description:
              "Leverage your existing technologies with plug-and-play integrations for your favorite third-party solutions. Plus, enjoy the freedom to customize your system to your organization’s unique needs—use Rhombus’ open API to build custom functionality. ",
            image: data.infoSlide5,
          },
        ],
      },
      customerReviews: [
        {
          image: data.review1,
          author: {
            avatar: data.avatar1,
            name: "Rob Zimprich",
            role: "CFO of UACDC",
          },
        },

        {
          image: data.review2,
          author: {
            avatar: data.avatar2,
            name: "RC Miles",
            role: "Global Director of Safety and Security at AHF",
          },
        },
        {
          image: data.review3,
          author: {
            avatar: data.avatar3,
            name: "Erik Tank",
            role:
              "Systems and Security Architect of East Moline School District 37",
          },
        },
        {
          image: data.review4,
          author: {
            avatar: data.avatar4,
            name: "John Eaton",
            role: "vCIO of Kelley Connect",
          },
        },
        {
          image: data.review5,
          author: {
            avatar: data.avatar5,
            name: "Susan Bohl",
            role: "Executive Director at Oklahoma One-Call System Inc.",
          },
        },
      ],
      formSection: {
        preTitle: "REQUEST DEMO",
        title: "Interested in Rhombus?",
        description:
          "Get a demo today and see how Rhombus transforms IT departments into more nimble and scalable teams.",
        learnLines: [
          "Why a cloud-native solution is superior to on-premise video security systems",
          "The benefits of using a unified platform with smart cameras, sensors, and integrations",
          "How to improve safety and productivity with powerful AI analytics",
        ],
        id: "fa7fb89b-ef66-4638-aa6e-e35b012fc6c6",
      },
    };
    return (
      <GlobalLayout color="var(--gradient-light)">
        <Helmet>
          <title>
            Rhombus for Executive Teams - Unify Your Security at Scale
          </title>
          <meta
            name="description"
            content="Protect your company with an all-in-one physical security solution that grows with your needs."
          />
        </Helmet>
        <TeamsPageTemplate data={pageData} />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: { eq: "components/demo/img/free-trial-header-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      logo1: file(
        relativePath: { eq: "components/common/logos/hurley-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo2: file(
        relativePath: { eq: "components/common/logos/aramark-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo3: file(
        relativePath: { eq: "components/common/logos/stryker-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo4: file(
        relativePath: { eq: "components/common/logos/lafs-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo5: file(
        relativePath: { eq: "components/common/logos/goodwill-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo6: file(
        relativePath: {
          eq: "components/common/logos/clark-construction-white.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      benefitImage1: file(
        relativePath: { eq: "components/teams/img/dome-camera-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefitImage2: file(
        relativePath: {
          eq: "components/teams/img/operations-dashboard-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefitImage3: file(relativePath: { eq: "images/trusted-partner.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefitImage4: file(
        relativePath: { eq: "components/ITPage/img/partnership-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide1: file(
        relativePath: { eq: "components/teams/img/occupancy-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide2: file(
        relativePath: { eq: "components/teams/img/multi-location-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide3: file(
        relativePath: { eq: "components/teams/img/device-suite-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide4: file(
        relativePath: { eq: "components/teams/img/dashboard-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      infoSlide5: file(
        relativePath: { eq: "components/teams/img/integrations-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      avatar1: file(
        relativePath: { eq: "components/common/img/headshots/rob-z.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      avatar2: file(
        relativePath: { eq: "components/common/img/headshots/rc-miles.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      avatar3: file(relativePath: { eq: "components/ITPage/img/erik.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      avatar4: file(
        relativePath: { eq: "components/common/img/headshots/john-eaton.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      avatar5: file(
        relativePath: { eq: "components/common/img/headshots/susan-b.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      review1: file(
        relativePath: { eq: "components/common/img/reviews/rob-z-review.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      review2: file(
        relativePath: {
          eq: "components/common/img/reviews/rc-miles-review.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      review3: file(relativePath: { eq: "components/common/img/review3.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      review4: file(
        relativePath: { eq: "components/common/img/reviews/john-e-review.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      review5: file(
        relativePath: { eq: "components/common/img/reviews/susan-b-review.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
